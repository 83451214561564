import React, { useEffect, useRef, useState } from 'react';
import axiosInstance from '../views/utils/axiosInstance';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Builder() {
    const { slugURL } = useParams();
    const [builderName, setBuilderName] = useState('');
    const [cityProjects, setCityProjects] = useState([]);
    const [cityProjectsDetail, setCityProjectsDetail] = useState({});
    const [location, setLocation] = useState('');
    const [loadingProjects, setLoadingProjects] = useState(true); 
    const [loadingDetails, setLoadingDetails] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false); 
    const [hasMoreProjects, setHasMoreProjects] = useState(true); 
    const [visibleProjects, setVisibleProjects] = useState([]);

    const location1 = useLocation();

    const navigate = useNavigate();

    const modalRef = useRef(null);

    const currentURL = window.location.origin + location1.pathname

    const openDetailModal = () => {
        if (modalRef.current) {
            modalRef.current.classList.add("active");
        }
    };
    const closeDetailModal = () => {
        if (modalRef.current) {
            modalRef.current.classList.remove("active");
        }
    };

    const fetchBuilderProjects = async () => {
        try {
            const response = await axiosInstance.get(`addProjects/getProjectByDeveloper/${slugURL}`);

            if (response.data) {
                // Filter projects with status set to true
                const filteredProjects = response.data.filter(project => project.status === true);

                setCityProjects(filteredProjects);
                setVisibleProjects(filteredProjects.slice(0, 6));
                setHasMoreProjects(filteredProjects.length > 6); 
            } else {
                setCityProjects([]);
                setVisibleProjects([]);
                setHasMoreProjects(false);
            }
        } catch (error) {
            setCityProjects([]);
            setVisibleProjects([]);
        } finally {
            setLoadingProjects(false); 
        }
    };

    useEffect(() => {
        fetchBuilderProjects();
    }, [slugURL]);

    const loadMoreProjects = () => {
        if (!hasMoreProjects || loadingMore) return;

        setLoadingMore(true);
        setTimeout(() => {
            const nextProjects = cityProjects.slice(
                visibleProjects.length,
                visibleProjects.length + 6
            );

            setVisibleProjects((prev) => [...prev, ...nextProjects]);
            setHasMoreProjects(cityProjects.length > visibleProjects.length + nextProjects.length);
            setLoadingMore(false);
        }, 1000);
    };

    const observer = useRef();
    const lastProjectRef = (node) => {
        if (loadingMore) return;

        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMoreProjects) {
                loadMoreProjects();
            }
        });

        if (node) observer.current.observe(node);
    };
    const fetchBuilderProjectsDetail = async () => {
        try {

            const response = await axiosInstance.get(`developers/getDeveloperBySlugURL/${slugURL}`);
            // console.log(response.data)
            // Check if response data exists and status is true
            if (response.data && response.data.status === true) {
                setCityProjectsDetail(response.data);
                setBuilderName(response.data.developerName || '');
                const metaTitle = response.data.metaTitle || 'Best Real Estate Consultancy Company in India | Star Estate';
                const metaKeyword = response.data.metaKeyword || 'Best Real Estate Consultancy Company in India | Star Estate';
                const metaDescription = response.data.metaDescription || 'Best Real Estate Consultancy Company in India | Star Estate';
                const schema = response.data.schema || {}; // Adjust if schema is part of the response


                // Set document title
                document.title = metaTitle;

                // Set meta keywords
                let metaKeywordTag = document.querySelector("meta[name='keywords']");
                if (metaKeywordTag) {
                    metaKeywordTag.setAttribute("content", metaKeyword);
                } else {
                    metaKeywordTag = document.createElement("meta");
                    metaKeywordTag.name = "keywords";
                    metaKeywordTag.content = metaKeyword;
                    document.head.appendChild(metaKeywordTag);
                }

                // Set meta description
                let metaDescriptionTag = document.querySelector("meta[name='description']");
                if (metaDescriptionTag) {
                    metaDescriptionTag.setAttribute("content", metaDescription);
                } else {
                    metaDescriptionTag = document.createElement("meta");
                    metaDescriptionTag.name = "description";
                    metaDescriptionTag.content = metaDescription;
                    document.head.appendChild(metaDescriptionTag);
                }

                // Set schema
                const schemaScript = document.getElementById("project-schema");
                if (schemaScript) {
                    schemaScript.textContent = JSON.stringify(schema);
                } else {
                    const newSchemaScript = document.createElement("script");
                    newSchemaScript.type = "application/ld+json";
                    newSchemaScript.id = "project-schema";
                    newSchemaScript.textContent = JSON.stringify(schema);
                    document.head.appendChild(newSchemaScript);
                }

            } else {
                // Redirect to 404 if status is not true or data is not available
                navigate('/404NotFound');
            }
        } catch (err) {
            navigate('/404NotFound');
            // console.error('Unexpected error:', err);
        } finally {
            setLoadingDetails(false); // Stop loading
        }
    };


    useEffect(() => {
        fetchBuilderProjects();
        fetchBuilderProjectsDetail();
    }, [slugURL]);

    const [showMore, setShowMore] = useState(false);

    const toggleReadMore = () => {
        setShowMore(!showMore);
    };

    const briefContent = cityProjectsDetail?.briefContent || '';
    const description = cityProjectsDetail?.description || '';

    return (
        <div>
            <Helmet>
                <meta name="twitter:title" content={cityProjectsDetail.metaTitle ? cityProjectsDetail.metaTitle : "Best Real Estate Consultancy Company in India | Star Estate"}/>
                <meta name="twitter:description" content={cityProjectsDetail.metaDescription ? cityProjectsDetail.metaDescription : "Star Estate is the best real estate consultancy company in India. Browse extensive range of luxury residential and commercial properties in India. Explore now."}/>   
                <meta property="og:title" content={cityProjectsDetail.metaTitle ? cityProjectsDetail.metaTitle : 'Best Real Estate Consultancy Company in India | Star Estate'}/>
                <meta property="og:description" content={cityProjectsDetail.metaDescription ? cityProjectsDetail.metaDescription : "Star Estate is the best real estate consultancy company in India. Browse extensive range of luxury residential and commercial properties in India. Explore now."}/>
                <meta property="og:url" content={currentURL}/>
                    <link rel="canonical" href={currentURL}/>
                    <meta name="twitter:image" content="https://www.starestate.com/assets/images/homebanner/banner-mall.webp"/>
                    <meta property="og:image" content="https://www.starestate.com/static/media/logo-starestate.6297e07e21c140ea9787.webp"/>
            </Helmet>
            <div className="insideBanner">
                <picture>
                    <source media="(max-width: 820px)" srcSet="../assets/images/banner-all-projects-m.jpg" />
                    <img src="../assets/images/banner-all-projects.jpg" className="h-100 object-cover" alt="Star Estate" />
                </picture>
                <div className="bannerContainer" style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center'
                }}>
                    <div className="container-lg">
                        <div className="heading mb-0 mx-auto">
                            <span className="h2 text-uppercase mb-0 text-texture" style={{ letterSpacing: "normal" }}>{cityProjectsDetail.developerName}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-100">
                <div className="container-lg">
                    <div className="breadcrumbContainer" aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                            <li className="breadcrumb-item"><a href="#">Builder</a></li>
                            <li className="breadcrumb-item active">{cityProjectsDetail.developerName || 'Developer'}</li>
                        </ol>
                    </div>
                </div>
            </div>

            <div className="w-100 padding">
                <div className="container-lg">
                    <div className="heading mx-auto">
                        <h1 className="h3 mb-0 text-center">{cityProjectsDetail.developerName || 'Developer'}</h1>

                    </div>

                    {/* Builder Details Loading */}
                    {loadingDetails ? (
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <span className="ml-2">Loading...</span>
                        </div>
                    ) : (
                        <div key={cityProjectsDetail._id} className="brief-page-content mb-3 text-lg-center">
                            <article
                                dangerouslySetInnerHTML={{
                                    __html: showMore ? description : briefContent
                                }}
                            ></article>
                            {description.trim() ? (
                                <div className='readmore mx-auto mt-3'>
                                    <button onClick={openDetailModal} className="button">
                                        {showMore ? 'Read less' : 'Read more'}
                                    </button>
                                </div>
                            ) : ''}


                            <div ref={modalRef} className="projectOverview-modal">
                                <div className="inner">
                                    <div className="projectModal-header">
                                        <h6 className="mb-0">Builder Details</h6>
                                        <button className="projectOverview-close" onClick={closeDetailModal}>
                                            &times;
                                        </button>
                                    </div>
                                    <div className="projectOverview-details scroller" dangerouslySetInnerHTML={{ __html: description }}>

                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Projects Section */}
                    <div className="row gap-row">
                        {loadingProjects ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                <span className="ml-2">Loading...</span>
                            </div>
                        ) : visibleProjects.length > 0 ? (
                            visibleProjects.map((project, index) => (
                                <div key={project._id} ref={index === visibleProjects.length - 1 ? lastProjectRef : null} className="col-lg-4 col-sm-6 project_box">
                                    <a href={`/${project.slugURL}`} target="_blank" rel="noopener noreferrer" className="project_box_inner">
                                        <div className="Project_box_img">
                                            <div className="reraBox position-absolute">
                                                <div className="qr_img">
                                                    <img src={`${axiosInstance.defaults.globalURL}${project.rera_qr}`} alt={project.projectName} />
                                                </div>
                                                <div className="rera_num">
                                                    <small className="mb-0">
                                                        <strong className="text-primary">Projects RERA No:</strong> {project.rera_no || 'N/A'}
                                                        <br />
                                                        <small className="small text-primary">
                                                            <i className="fa fa-link"></i> {project.reraWebsite || 'www.up-rera.in/projects'}
                                                        </small>
                                                    </small>
                                                </div>
                                            </div>
                                            <div className="img-fluid">
                                                <img src={`${axiosInstance.defaults.globalURL}${project.project_thumbnail}`} alt={project.projectName} />
                                            </div>
                                        </div>
                                        <div className="project_box_details">
                                            <div className="project_developer_detail">
                                                <h4 className="mb-0 project_name">{project.projectName || 'Project Name'}</h4>
                                                <h6 className="mb-0 project_price">
                                                    {project.projectPrice.trim().toLowerCase() === 'price on request' || project.projectPrice.trim().toLowerCase() === 'revealing soon'
                                                        ? `${project.projectPrice.trim()}`
                                                        : <>
                                                            <i className="fa fa-indian-rupee-sign"></i>{project.projectPrice}*
                                                        </>}
                                                </h6>
                                            </div>
                                            <div className="project_status_detail">
                                                <span className="project_box_location">
                                                    <i className="fa fa-map-marker-alt"></i> {project.projectAddress || 'Location not available'}
                                                </span>
                                                <span className="project_box_status">
                                                    <i className="fa-brands fa-font-awesome"></i> {Array.isArray(project.project_status)
                                                        ? project.project_status.join(', ')
                                                        : project.project_status}
                                                </span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))
                        ) : (
                            <p>No projects available.</p>
                        )}
                        {loadingMore && (
                            <div className="d-flex justify-content-center align-items-center w-100">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading more...</span>
                                </div>
                                <span className="ml-2"></span>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Builder;
