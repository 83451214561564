import React, { useEffect, useState } from 'react'
import axiosInstance from '../utils/axiosInstance';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function ClientsSpeak() {
    const [testi, setTesti] = useState([]);
    const location = useLocation();

  const currentURL = window.location.origin + location.pathname
    useEffect(() => {
        const fetchTest = async () => {
            try {
                const response = await axiosInstance.get(`clientSpeak/getClientWords`);
                const filteredTest = response.data.filter(test => test.status === true);
                setTesti(filteredTest);
            } catch (error) {
                console.error('Failed to fetch Awards', error);
            }
        };
        fetchTest();
    }, []);
    return (
        <div>
            <Helmet>
                    <title>{'Best Real Estate Consultancy Company in India | Star Estate'}</title>
                    <meta name="description" content={'Star Estate is the Best Real Estate Consultancy Company In India. Browse Extensive Range Of Luxury Residential and Commercial Properties in India. Explore Now.'} />
                    <meta name="keywords" content={'Real Estate Consultancy Company, Real Estate Consultancy Company in India, Best Real Estate Consultancy,  Real Estate Company, Real Estate company in India'} />
                    <meta name="twitter:title" content={"Best Real Estate Consultancy Company in India | Star Estate"}/>
                    <meta name="twitter:description" content={"Star Estate is the best real estate consultancy company in India. Browse extensive range of luxury residential and commercial properties in India. Explore now."}/>   
                    <meta property="og:title" content={'Best Real Estate Consultancy Company in India | Star Estate'}/>
                    <meta property="og:description" content={"Star Estate is the best real estate consultancy company in India. Browse extensive range of luxury residential and commercial properties in India. Explore now."}/>
                    <meta property="og:url" content={currentURL}/>
                    <link rel="canonical" href={currentURL}/>
                    <meta name="twitter:image" content="https://www.starestate.com/assets/images/homebanner/banner-mall.webp"/>
                    <meta property="og:image" content="https://www.starestate.com/static/media/logo-starestate.6297e07e21c140ea9787.webp"/>
            </Helmet>
            <div className="insideBanner">
                <picture>
                    <source media="(max-width: 820px)" srcset="assets/images/banner-emi-calculator-m.jpg" />
                    <img src="assets/images/banner-emi-calculator.jpg" className="h-100 object-cover" alt="Star Estate" />
                </picture>
            </div>
            <div className="w-100">
                <div className="container-lg">
                    <div className="breadcrumbContainer" aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                            <li className="breadcrumb-item active">Client's Speak</li>
                        </ol>
                    </div>
                </div>
            </div>
            <div className="w-100 padding">
                <div className="container-lg">
                    <div className="heading mx-sm-auto text-sm-center">
                        <h3 className="mb-0">Client's Speak</h3>
                    </div>
                    <div className="testim-slider">
                        <div className="row g-4 ">
                            {testi.map((tests, index) => (
                                <div className="col-lg-6 testimBox">
                                    <div className="inner common-border">
                                        <i><img src="assets/images/icons/double-quote.png" alt="" /></i>

                                        <div key={tests._id} className="testim-text">
                                            <p>{tests.clientWords}</p>
                                            <h6 className="testim-name">{tests.clientName} <small>{tests.clientSubHeading}</small></h6>
                                        </div>

                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default ClientsSpeak