import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';
import { Helmet } from 'react-helmet';

function BlogDetails() {
    const { slugURL } = useParams();
    const [blogDetails, setBlogDetails] = useState([]);
    const [recentBlogs, setRecentBlogs] = useState([]);
    const [error, setError] = useState('');
    const [loadingBlog, setLoadingBlog] = useState(true);
    const [loadingRecent, setLoadingRecent] = useState(true);
    const footerRef = useRef(null);
    const [isSticky, setIsSticky] = useState(false);
    const [message, setMessage] = useState("");
    const [previousBlog, setPreviousBlog] = useState([]);
    const [nextBlog, setNextBlog] = useState([]);

    const location = useLocation();

  const currentURL = window.location.origin + location.pathname

    useEffect(() => {
        const fetchBlogDetailsData = async () => {
            try {
                const response = await axiosInstance.get(`/blogs/getBlogBySlugURL/${slugURL}`);
                const fetchedData = response.data;
                setBlogDetails([fetchedData]);
                document.title = fetchedData.metaTitle || 'Best Real Estate Consultancy Company in India | Star Estate';
                document.querySelector('meta[name="description"]').setAttribute("content", fetchedData.metaDescription || 'Best Real Estate Consultancy Company in India | Star Estate');
                document.querySelector('meta[name="keywords"]').setAttribute("content", fetchedData.metaKeyword || 'Best Real Estate Consultancy Company in India | Star Estate');

                if (fetchedData && fetchedData.blogsCategory) {
                    console.log(fetchedData.blogsCategory);
                    fetchedData.blogsCategory === 'blog' ? fetchRecentBlogs() : fetchRecentNews();
                }
               
            } catch (error) {
                setError('Error fetching blog data');
                console.error('Error fetching blog data:', error);
            } finally {
                setLoadingBlog(false);
            }
        };
        fetchBlogDetailsData();
    }, [slugURL]);

    const fetchRecentBlogs = async () => {
        try {
            const response = await axiosInstance.get('/blogs/getBlog');
            const fetchedBlogs = response.data;
            const recentBlogsFiltered = fetchedBlogs
                .filter(blog => blog.slugURL !== slugURL && blog.status !== false && blog.blogsCategory === 'blog')
                .slice(0, 5);
            setRecentBlogs(recentBlogsFiltered);
        } catch (error) {
            setError('Error fetching recent blogs');
            console.error('Error fetching recent blogs:', error);
        } finally {
            setLoadingRecent(false);
        }
    };

    const fetchRecentNews = async () => {
        try {
            const response = await axiosInstance.get('/blogs/getBlog');
            const fetchedBlogs = response.data;
            const recentBlogsFiltered = fetchedBlogs
                .filter(blog => blog.slugURL !== slugURL && blog.status !== false && blog.blogsCategory === 'news')
                .slice(0, 5);
            setRecentBlogs(recentBlogsFiltered);
        } catch (error) {
            setError('Error fetching recent blogs');
            console.error('Error fetching recent blogs:', error);
        } finally {
            setLoadingRecent(false);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIsSticky(!entry.isIntersecting);
        }, { threshold: 1.0 });

        if (footerRef.current) {
            observer.observe(footerRef.current);
        }

        return () => {
            if (footerRef.current) {
                observer.unobserve(footerRef.current);
            }
        };
    }, []);

    const shareLinks = [
        {
            name: "Facebook",
            icon: <i className="fa-brands fa-facebook"></i>,
            url: "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href,
            color: "#3b5998",
        },
        {
            name: "Twitter",
            icon: <i className="fa-brands fa-x-twitter"></i>,
            url: "https://twitter.com/intent/tweet?url=" + window.location.href,
            color: "#00acee",
        },
        {
            name: "Pinterest",
            icon: <i className="fa-brands fa-pinterest"></i>,
            url: "https://pinterest.com/pin/create/button/?url=" + window.location.href,
            color: "#c8232c",
        },
        {
            name: "WhatsApp",
            icon: <i className="fa-brands fa-whatsapp"></i>,
            url: "https://api.whatsapp.com/send?text=" + window.location.href,
            color: "#25D366",
        },
        {
            name: "LinkedIn",
            icon: <i className="fa-brands fa-linkedin"></i>,
            url: "https://www.linkedin.com/sharing/share-offsite/?url=" + window.location.href,
            color: "#0077b5",
        },
        {
            name: "Reddit",
            icon: <i className="fa-brands fa-reddit"></i>,
            url: "https://reddit.com/submit?url=" + window.location.href,
            color: "#FF4500",
        },
        {
            name: "Copy Link",
            icon: <i className="fa-solid fa-copy"></i>,
            action: () => {
                navigator.clipboard.writeText(window.location.href);
                setMessage("Link Copied!");
                setTimeout(() => setMessage(""), 2000);
            },
            color: "#000",
        },
    ];
// 02241792999
    const handleShare = (url, action) => {
        if (action) {
            action();
        } else {
            window.open(url, "_blank");
        }
    };

    return (
        <div>
            
             {blogDetails && (
                <Helmet>
                    <title>{blogDetails.metaTitle || 'Best Real Estate Consultancy Company in India | Star Estate'}</title>
                    <meta name="description" content={blogDetails.metaDescription || 'Star Estate is the Best Real Estate Consultancy Company In India. Browse Extensive Range Of Luxury Residential and Commercial Properties in India. Explore Now.'} />
                    <meta name="keywords" content={blogDetails.metaKeyword || 'Real Estate Consultancy Company, Real Estate Consultancy Company in India, Best Real Estate Consultancy,  Real Estate Company, Real Estate company in India'} />
                    <meta name="twitter:title" content={blogDetails.metaTitle ? blogDetails.metaTitle : "Best Real Estate Consultancy Company in India | Star Estate"}/>
                    <meta name="twitter:description" content={blogDetails.metaDescription ? blogDetails.metaDescription : "Star Estate is the best real estate consultancy company in India. Browse extensive range of luxury residential and commercial properties in India. Explore now."}/>   
                    <meta property="og:title" content={blogDetails.metaTitle ? blogDetails.metaTitle : 'Best Real Estate Consultancy Company in India | Star Estate'}/>
                    <meta property="og:description" content={blogDetails.metaDescription ? blogDetails.metaDescription : "Star Estate is the best real estate consultancy company in India. Browse extensive range of luxury residential and commercial properties in India. Explore now."}/>
                    <meta property="og:url" content={currentURL}/>
                    <link rel="canonical" href={currentURL}/>                    
                    <meta name="twitter:image" content={blogDetails.blogsImage ? `${axiosInstance.defaults.globalURL}${blogDetails.blogsImage}` : "https://www.starestate.com/assets/images/homebanner/banner-mall.webp"}/>
                    <meta property="og:image" content={blogDetails.blogsImage ? `${axiosInstance.defaults.globalURL}${blogDetails.blogsImage}` :"https://www.starestate.com/static/media/logo-starestate.6297e07e21c140ea9787.webp"}/>
                    <meta name="robots" content="max-image-preview:large"/>
                    <meta name="Publisher" content="Star Estate" />
                    <meta property="article:author" content="https://www.starestate.com/" />
                    <script type="application/ld+json">
                    {JSON.stringify(
                     {
                        "@context": "https://schema.org",
                        "@type": "NewsArticle",
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": `${axiosInstance.defaults.globalURL}${blogDetails.blogsImage}`
                        },
                        "headline": `${blogDetails.metaTitle || 'Best Real Estate Consultancy Company in India | Star Estate'}`,
                        "description": `${blogDetails.metaDescription || 'Star Estate is the Best Real Estate Consultancy Company In India. Browse Extensive Range Of Luxury Residential and Commercial Properties in India. Explore Now.'}`,
                        "image": `${axiosInstance.defaults.globalURL}${blogDetails.blogsImage}`,
                        "author": {
                            "@type": "Organization",
                            "name": "Star Estate",
                            "url": "https://www.starestate.in/"
                        },
                        "publisher": {
                            "@type": "Organization",
                            "name": "Star Estate",
                            "logo": {
                                "@type": "ImageObject",
                                "url": "https://www.starestate.in/assets/images/logo-starestate.png"
                            }
                        },
                        "datePublished": `${blogDetails.blogsDate || "2024-09-06 T08:00:00+08:00"}`,
                        "dateModified": `${blogDetails.blogsDate || "2024-09-06 T08:00:00+08:00"}`
                    }
                    )}
                </script>
                </Helmet>
            )}
            <div className="emptyBox"></div>
            {message && (
            <div className="share-message-overlay">
                <div className="share-message">{message}</div>
            </div>
            )}
            <div className="w-100">
                <div className="container-lg">
                    {loadingBlog ? (
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <span className="ml-2">Loading...</span>
                        </div>
                    ) : error ? (
                        <div className="alert alert-danger">{error}</div>
                    ) : (
                        blogDetails.map((blogs, index) => (
                            <div key={index} className="breadcrumbContainer" aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                    <li className="breadcrumb-item">Media</li>
                                    <li className="breadcrumb-item">{blogs.blogsCategory === 'blog' ? <Link to='/blogs'>Blogs</Link> : <Link to='/news'>News</Link>}</li>
                                    <li className="breadcrumb-item active">{blogs.blogsName}</li>
                                </ol>
                            </div>
                        ))
                    )}
                </div>
            </div>

            <div className="w-100 padding">
                <div className="container-lg">
                    <div className="row gap-row">
                        {loadingBlog ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                <span className="ml-2">Loading...</span>
                            </div>
                        ) : blogDetails.map((blogs, index) => (
                            <React.Fragment key={index}>
                                <div className="col-xl-8 col-lg-7 blogTextContainer">
                                    <div className="inner pr-lg-3">
                                        <div className="heading">
                                            <h1 className="h2 mb-0">{blogs.blogsName}</h1>
                                        </div>
                                        <div className="courtesy">
                                            <span className="posted_on">Posted on: <strong>{new Date(blogs.blogsDate).toLocaleDateString('en-GB').replace(/\//g, '-')}</strong></span>
                                            <span>Courtesy: <strong>{blogs.blogsBy}</strong></span>
                                        </div>
                                        <div className='courtesy'><span className="posted_on">By <strong>Star Estate</strong></span> 
                                        <span className="share-buttons mt-0 mb-0">
                                            <div className="share-icons">
                                                {shareLinks.map((link, index) => (
                                                    <button
                                                        key={index}
                                                        className="share-button"
                                                        onClick={() => handleShare(link.url, link.action)}
                                                        style={{ backgroundColor: link.color }}
                                                        title={link.name}
                                                    >
                                                        {link.icon}
                                                    </button>
                                                ))}
                                            </div>
                                        </span>
                                        </div>
                                        
                                        
                                        
                                        <div className="blogTextarea w-100">
                                            <div className="img-fluid">
                                                <img src={`${axiosInstance.defaults.globalURL}${blogs.blogsImage}`} alt={blogs.imageTitle || 'Blog Image'} />
                                                <em>{blogs.imageTitle}</em>
                                            </div>
                                            <p dangerouslySetInnerHTML={{ __html: blogs.content }} />
                                        </div>
                                        {blogs.blogsLink && (
                                            <div className="readmore w-100 d-flex">
                                                <a
                                                    href={blogs.blogsLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="button"
                                                >
                                                    Read More
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                    <div className="share-buttons" style={{alignItems: 'start'}}>
                                    <div className="share-header">
                                        <span className="share-icon">🔗</span>
                                        <span>Share</span>
                                    </div>
                                            <div className="share-icons">
                                                {shareLinks.map((link, index) => (
                                                    <button
                                                        key={index}
                                                        className="share-button"
                                                        onClick={() => handleShare(link.url, link.action)}
                                                        style={{ backgroundColor: link.color }}
                                                        title={link.name}
                                                    >
                                                        {link.icon}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="p-4 bg-gray-gradient-box">
                                            <div className="form-container">
                                                <div className="row align-items-center gy-4 gx-3">
                                                {/* Logo Section */}
                                                <div className="col-md-2 text-center">
                                                    <img
                                                    src="../assets/images/logo-starestate.webp"
                                                    alt="Star Estate"
                                                    className="img-fluid"
                                                    />
                                                </div>

                                                {/* Brand Name Section */}
                                                <div className="col-md-4 text-center text-md-start">
                                                    <div><strong className="brand-name">Star Estate</strong></div>
                                                    <span><a href='https://www.starestate.com' target='_blank'>https://www.starestate.com</a></span>
                                                </div>

                                                {/* Social Media Icons Section */}
                                                <div className="col-md-6">
                                               
                                                    <div className="social-icons d-flex justify-content-center justify-content-md-end list-inline socialIcons solid">
                                                    <a
                                                        href="https://www.facebook.com/starestate.in"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="social-icon"
                                                        aria-label="Facebook"
                                                    >
                                                        <i className="fab fa-facebook-f"></i>
                                                    </a>
                                                    <a
                                                        href="https://www.instagram.com/starestate_official/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="social-icon"
                                                        aria-label="Instagram"
                                                    >
                                                        <i className="fab fa-instagram"></i>
                                                    </a>
                                                    <a
                                                        href="https://www.linkedin.com/company/star-estate"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="social-icon"
                                                        aria-label="LinkedIn"
                                                    >
                                                        <i className="fab fa-linkedin-in"></i>
                                                    </a>
                                                    <a
                                                        href="https://twitter.com/starestate2"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="social-icon"
                                                        aria-label="X (Twitter)"
                                                    >
                                                        <i className="fab fa-x-twitter"></i>
                                                    </a>
                                                    <a
                                                        href="https://www.youtube.com/channel/UCwfDf7Ut8jrkjiBeRnbZUPw"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="social-icon"
                                                        aria-label="YouTube"
                                                    >
                                                        <i className="fab fa-youtube"></i>
                                                    </a>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            </div>

                                </div>

                                <div
                                    className="col-xl-4 col-lg-5 position-relative pageAside"
                                    style={{
                                        position: isSticky ? 'sticky' : 'relative',
                                        top: isSticky ? '20px' : 'auto',
                                        transition: 'top 0.3s',
                                        height: 'fit-content',
                                    }}
                                >
                                    {loadingRecent ? (
                                        <div className="d-flex justify-content-center align-items-center">
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            <span className="ml-2">Loading...</span>
                                        </div>
                                    ) : recentBlogs.length > 0 && (
                                        <div className="aside-inner" style={{ top: "60px" }}>
                                            <aside className="topRatedProjectShowcase common-border mt-0">
                                                <div className="heading ml-0">
                                                    <h6 className="mb-0 text-primary">Recent Posts</h6>
                                                </div>
                                                <div className="topRatedProjectsContainer">
                                                    {recentBlogs.map((recentBlog, idx) => (
                                                        <div key={idx} className="topRatedProjectBox">
                                                            <div className="inner">
                                                                <div className="img-fluid">
                                                                    <img src={`${axiosInstance.defaults.globalURL}${recentBlog.blogsImage}`} alt={recentBlog.blogsName || 'Blog Image'} />
                                                                </div>
                                                                <div className="boxDetails">
                                                                    <Link to={`/blogs/${recentBlog.slugURL}`}>
                                                                        {recentBlog.blogsName}
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </aside>
                                        </div>
                                    )}
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default BlogDetails;
